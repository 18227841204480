import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { uiActions } from "../../shared/store/ui-Slice";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { dataActions } from "../../shared/store/data-Slice";
import { CloseDarkBtn } from "../svg/AllSvg";

const LangPopup = ({ onConfirm, selectLanguage }: any) => {
    const langOpen: boolean = useSelector((state: any) => state.ui.langOpen);
    const currentCity: string = useSelector((state: any) => state.data.currentCity);
    const color: any = useSelector((state: any) => state.ui.color);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(uiActions.setLangOpen(false))
        }
    };

    useEffect(() => {
        let btn: any = document.getElementById("continue");
        btn.focus();
    }, [])

    return (
        <>
            {langOpen &&
                <div className="fixed inset-0 z-[34] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[rgba(88,88,88,0.40)] backdrop-blur-[2.5px]" onClick={() => dispatch(uiActions.setLangOpen(false))}
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="md:mx-auto bg-white dark:bg-[#333333] rounded-[10px] flex flex-col items-center justify-center w-[275px] ss:w-[320px] h-auto sm:w-[446px] z-[3] relative p-[30px]">
                            <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="cursor-pointer flex items-center justify-center opacity-[0.3] absolute top-[8px] right-[8px] mm:top-[15px] mm:right-[15px]  focus:shadow-[0_0_6px_0px_#32435F] w-[19px] h-[19px] rounded-full" onClick={() => dispatch(uiActions.setLangOpen(false))}>
                                <CloseDarkBtn />
                            </button>

                            <img src={require("../../assets/images/Mask group35.png")} alt="pattern" className="absolute left-0 bottom-0 z-[1] sm:w-auto sm:h-auto w-[200px] h-[200px]" />

                            <img src={require("../../assets/images/lang.png")} alt="language" />

                            <p className="text-secondary text-xs sm:text-sm 1xl:text-base font-normal max-w-[195px] text-center pt-[20px]">{t("popup.lang")}</p>

                            <button id="continue" style={{ background: `linear-gradient(80deg, ${!!currentCity ? `${color[currentCity]?.primary}` : "#5C0054"} -0.21%, ${!!currentCity ? `${color[currentCity]?.secondary}` : "#A71099"} 86.05%)` }} className="text-white text-sm font-bold capitalize   w-[200px] min-h-[40px] flex items-center justify-center mt-[18px] rounded-[14px] shadow-[0px_1px_4px_0px_rgba(0,0,0,0.25)] z-[3] focus:shadow-[0_0_6px_0px_#32435F] hover:shadow-[0_0_6px_0px_#32435F]" onClick={() => { onConfirm(); dispatch(dataActions.setLostfoundApiCall(true)); dispatch(dataActions.setFacilityApicall(true)); }}>{lang === "hi" && selectLanguage?.text} {t("popup.switch_to")} {lang === "en" && selectLanguage?.text}</button>

                            <button className="border-0   text-[#C6C6C6] text-sm font-bold w-[200px] flex items-center justify-center py-[10px] mt-[10px] focus:shadow-[0_0_6px_0px_#32435F] hover:shadow-[0_0_6px_0px_#32435F] rounded-[14px] z-[3]" onClick={() => dispatch(uiActions.setLangOpen(false))}>{t("popup.cancel")}</button>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default LangPopup;