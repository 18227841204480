import { Outlet, useLocation } from "react-router-dom";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import MainHeader from "../../components/layout/MainHeader";
import WebPopup from "../../components/layout/WebPopup";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Login from "../../components/auth/Login";
import Register from "../../components/auth/Register";
import ProfileCreated from "../../components/auth/ProfileCreated";
import FeedbackForm from "../../components/home/FeedbackForm";
import FeedbackSuccess from "../../components/home/FeedbackSuccess";
import LoginWarn from "../../components/home/LoginWarn";
import ImpNotice from "../../components/form/ImpNotice";

const MainLayout = () => {
    const webOpen: boolean = useSelector((state: any) => state.ui.webOpen);
    const langOpen: boolean = useSelector((state: any) => state.ui.langOpen);
    const loginOpen: boolean = useSelector((state: any) => state.data.loginOpen);
    const registerOpen: boolean = useSelector((state: any) => state.data.registerOpen);
    const profileOpen: boolean = useSelector((state: any) => state.data.profileOpen);
    const feedbackOpen: boolean = useSelector((state: any) => state.data.feedbackOpen);
    const feedbackSuccess: boolean = useSelector((state: any) => state.data.feedbackSuccess);
    const loginWarnOpen: boolean = useSelector((state: any) => state.data.loginWarnOpen);
    const impNotice: any = useSelector((state: any) => state.data.impNotice);
    const location = useLocation();

    useEffect(() => {
        if (webOpen || langOpen || loginOpen || registerOpen || profileOpen || feedbackOpen || loginWarnOpen || feedbackSuccess || impNotice) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [webOpen, langOpen, loginOpen, registerOpen, profileOpen, feedbackOpen, feedbackSuccess, loginWarnOpen, impNotice]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className='w-full h-full bg-white'>
            {webOpen && <WebPopup />}
            {loginOpen && <Login />}
            {registerOpen && <Register />}
            {profileOpen && <ProfileCreated />}
            {feedbackOpen && <FeedbackForm />}
            {feedbackSuccess && <FeedbackSuccess />}
            {loginWarnOpen && <LoginWarn />}
            {impNotice && <ImpNotice />}
            <MainHeader />
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
};

export default MainLayout;