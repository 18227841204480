/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from 'react-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { languages } from '../../shared/utils/languages';
import { uiActions } from '../../shared/store/ui-Slice';
import { useTranslation } from 'react-i18next';
import LangSelect from '../form/LangSelect';
import { useEffect, useRef, useState } from 'react';
import { Call, DarkMode, Language, LightMode, Login, Schema, Search } from '../svg/AllSvg';
import { useNavigate, useParams } from 'react-router-dom';
import LangPopup from './LangPopup';
import { dataActions } from '../../shared/store/data-Slice';
import ProfilePopup from './ProfilePopup';
import SearchOpen from './SearchOpen';

const MainHeader = () => {
    const langOpen: boolean = useSelector((state: any) => state.ui.langOpen);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const currentCity: string = useSelector((state: any) => state.data.currentCity);
    const helpLine: any = useSelector((state: any) => state.data.helpLine);
    const profile: boolean = useSelector((state: any) => state.data.profile);
    const user: any = useSelector((state: any) => state.auth.user);
    const isLogin: boolean = useSelector((state: any) => state.auth.isLogin);
    const theme: any = useSelector((state: any) => state.ui.theme);
    const [langValue, setLangValue] = useState<any>(null);
    const [selectLanguage, setSelectLanguage] = useState<any>(null);
    const [fontOpen, setFontOpen] = useState<any>(false);
    const [search, setSearch] = useState<string>("");
    const [searchOpen, setSearchOpen] = useState<any>(false);
    const [langFocus, setLangFocus] = useState<boolean>(false);
    const font: number = useSelector((state: any) => state.ui.font);
    const hicities: any = useSelector((state: any) => state.data.hicities);
    const { i18n } = useTranslation('common');
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const params = useParams();
    const ref: any = useRef();
    const ref1: any = useRef();

    useEffect(() => {
        document.documentElement.style.fontSize = "16px";
        dispatch(uiActions.changeFont(16));
    }, [])

    useEffect(() => {
        !!params?.search ? setSearch(params?.search) : setSearch("")
    }, [params?.search])

    useEffect(() => {
        if (searchOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [searchOpen]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                dispatch(dataActions.setProfile(false));
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref1.current && !ref1.current.contains(event.target)) {
                setFontOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref1]);

    const handleChange = async () => {
        if (selectLanguage?.value === "en") {
            let url: any = await window.location.pathname?.replace(`/${lang}`, "")
            navigate(!!window.location?.search ? (url + window.location?.search) : url)
        } else {
            let url: any = await window.location.pathname?.replace(lang, selectLanguage?.value)
            !!params?.lang ? navigate(!!window.location?.search ? (url + window.location?.search) : url) : navigate(`/${selectLanguage?.value}` + (!!window.location?.search ? ((window.location.pathname?.replace(`/${lang}`, "")) + window.location?.search) : (window.location.pathname?.replace(`/${lang}`, ""))))
        }
        dispatch(uiActions.changeLang(selectLanguage?.value));
        i18n.changeLanguage(selectLanguage?.value);
        dispatch(uiActions.setLangOpen(false))
    };

    useEffect(() => {
        if (!!params?.lang) {
            if (params?.lang === "hi") {
                let obj: any = languages?.find((item: any) => item?.value === params?.lang);

                if (!!obj && Object?.keys(obj)?.length > 0) {
                    setLangValue(obj)
                    i18n.changeLanguage(obj?.value);
                    dispatch(uiActions.changeLang(obj?.value));
                } else {
                    setLangValue({ label: "EN", value: "en" })
                    i18n.changeLanguage("en");
                    dispatch(uiActions.changeLang("en"));
                }
            } else {
                navigate("/404")
            }
        } else {
            setLangValue({ label: "EN", value: "en" })
            i18n.changeLanguage("en");
            dispatch(uiActions.changeLang("en"));
        }
    }, [params?.lang])

    const handleFont = (f: any) => {
        if (f === "A") {
            document.documentElement.style.fontSize = "16px";
            dispatch(uiActions.changeFont(16));
        }

        if (f === "A-") {
            if (font !== 12) {
                const cFont: number = font - 2;
                document.documentElement.style.fontSize = `${cFont}px`;
                dispatch(uiActions.changeFont(cFont));
            }
        }

        if (f === "+A") {
            if (font !== 20) {
                const cFont: number = font + 2;
                document.documentElement.style.fontSize = `${cFont}px`;
                dispatch(uiActions.changeFont(cFont));
            }
        }
    };

    const handleSiteMap = () => {
        !!params?.lang ? navigate(`/${params?.lang}/site-map`) : navigate("/site-map")
    }

    const getLang = () => {
        let arr: any = [];

        let index = 0;
        for (const key in helpLine) {
            arr.push(
                <div className={`flex items-center gap-[8px] ${!!currentCity && (key === currentCity ? "flex" : "hidden")}`} key={index}>
                    <div className='opacity-[0.4]'>
                        <Call />
                    </div>
                    <a href={'tel:+91' + helpLine[key]?.replace('-', '')?.substr(1, 10)} className='text-secondary dark:text-white hover:underline focus:underline'>
                        <p className='cursor-pointer text-secondary dark:text-white font-bold text-[11px] mds:text-[12px] capitalize flex items-center tracking-wide'>{`${!!hicities && hicities?.[key]} | ${helpLine[key]}`}</p>
                    </a>
                </div>
            );
            index++;
        }

        return arr;
    };

    const handleClick = () => {
        const secondButton: any = document.getElementById("skip-page");
        secondButton.click();
    }

    const handleScreenReader = () => {
        !!params?.lang ? navigate(`/${params?.lang}/screen-reader-access`) : navigate("screen-reader-access")
    }

    const handleSearch = () => {
        if (!!search) {
            !!params?.lang ? navigate(`/${params?.lang}/search/${search}`) : navigate(`/search/${search}`)
            setSearchOpen(false)
        }
    }

    const handleThemeSwitch = (theme: any) => {
        dispatch(uiActions.changeTheme(theme));
        localStorage.setItem("theme", theme);
    };

    const handleInput = (e: any) => {
        if (e.key === "Enter") {
            if (!!search) {
                !!params?.lang ? navigate(`/${params?.lang}/search/${search}`) : navigate(`/search/${search}`)
                setSearchOpen(false)
            }
        }
    }

    return (
        <>
            {/* lang change conformation popup */}
            {langOpen && <LangPopup onConfirm={handleChange} selectLanguage={selectLanguage} />}
            {searchOpen && <SearchOpen setSearchOpen={setSearchOpen} searchOpen={searchOpen} setSearch={setSearch} search={search} handleSearch={handleSearch} handleInput={handleInput} />}

            <div className='h-full py-[4px] flex-col mm:flex-row mm:flex items-center justify-between w-full border-b border-opacity-[0.2] border-secondary dark:border-white dark:bg-[#272727]' >
                <div className='px-0 pb-[4px] mm:py-0 border-b border-opacity-[0.2] border-secondary dark:border-white mm:border-0 flex items-center mm:h-[22px]'>
                    <div className="flex items-center h-[22px]">
                        <div className='px-[4px] h-[22px]'>
                            <div className={`px-[6px] flex items-center cursor-pointer h-[22px] rounded-[5px] hover:shadow-[0_0_6px_0px_#32435F] hover:dark:shadow-[0_0_6px_0px_#cdcdcd] ${langFocus && "shadow-[0_0_6px_0px_#32435F] focus:dark:shadow-[0_0_6px_0px_#cdcdcd]"}`} aria-label={t("aria_label.lang")} title={t("aria_label.lang")}>
                                <Language />
                                <LangSelect languages={languages} handleChange={handleChange} langValue={langValue} setSelectLanguage={setSelectLanguage} setLangFocus={setLangFocus} />
                            </div>

                        </div>
                        <div className='px-[4px] border-l mm:border-r border-opacity-[0.2] border-secondary dark:border-white h-[22px]'>
                            <button className="flex items-center hover:underline focus:underline text-secondary h-[22px]" onClick={handleClick}>
                                <Link id="skip-page" to="home" spy={true} smooth={true} className="text-secondary dark:text-white text-[11px] mds:text-[12px] font-bold cursor-pointer h-[22px] flex items-center justify-center px-[6px] whitespace-nowrap">{t("main_header.skip_main_content")}</Link>
                            </button>
                        </div>

                        <div className='px-[4px] border-l mm:border-l-0 mm:border-r border-opacity-[0.2] border-secondary dark:border-white h-[22px]'>
                            <button className="flex items-center h-[22px] whitespace-nowrap hover:underline focus:underline text-secondary" onClick={handleScreenReader}>
                                <h4 className="text-secondary dark:text-white text-[11px] mds:text-[12px] font-bold cursor-pointer h-[22px] flex items-center justify-center px-[6px]">{t("main_header.screen_reader_access")}</h4>
                            </button>
                        </div>
                    </div>
                </div>

                <div className='py-[4px] flex items-center justify-end mm:justify-center pl-[10px] h-full whitespace-nowrap'>
                    <div className="flex items-center justify-end h-full">
                        <div className='flex xxl:items-center flex-col xxl:flex-row gap-x-[10px] pr-[10px] h-full'>
                            <>
                                <p className='text-[#B03028] text-[11px] mds:text-[12px] font-bold text-center xxl:text-left'>{t("main_header.help_line")}</p>
                                <div className='flex 1.2xl:items-center flex-col 1.2xl:flex-row gap-x-[10px]'>
                                    {getLang()}
                                </div>
                            </>
                        </div>
                        <div className='border-l border-opacity-[0.2] border-secondary dark:border-white h-[22px] px-[2px]'>
                            <button aria-label={t("aria_label.search")} title={t("aria_label.search")} className='min-w-[22px] cursor-pointer flex items-center justify-center focus:shadow-[0_0_6px_0px_#32435F] hover:shadow-[0_0_6px_0px_#32435F] focus:dark:shadow-[0_0_6px_0px_#cdcdcd] hover:dark:shadow-[0_0_6px_0px_#cdcdcd] rounded-[5px] h-[22px]  ' onClick={() => setSearchOpen(true)}>
                                <Search />
                            </button>
                        </div>
                        <div className='border-l border-r border-opacity-[0.2] border-secondary dark:border-white h-[22px] px-[2px]'>
                            <button aria-label={t("aria_label.sitemap")} title={t("aria_label.sitemap")} className="min-w-[22px]  flex items-center justify-center h-[22px] focus:shadow-[0_0_6px_0px_#32435F] hover:shadow-[0_0_6px_0px_#32435F] focus:dark:shadow-[0_0_6px_0px_#cdcdcd] hover:dark:shadow-[0_0_6px_0px_#cdcdcd] rounded-[5px]" onClick={handleSiteMap}>
                                <Schema />
                            </button>
                        </div>

                        <div className="px-[10px] hidden items-center gap-[5px] mds:flex">
                            <button aria-label={t("aria_label.d_font")} title={t("aria_label.d_font")} className=" rounded-[3.2px] bg-[#EBEBEB] w-[23px] h-[22px] flex items-center justify-center text-[11.5px] text-secondary dark:!text-secondary font-bold focus:shadow-[0_0_6px_0px_#32435F] focus:dark:shadow-[0_0_6px_0px_#cdcdcd] hover:shadow-[0_0_6px_0px_#32435F] hover:dark:shadow-[0_0_6px_0px_#cdcdcd]" onClick={() => handleFont("A-")}>A-</button>
                            <button aria-label={t("aria_label.n_font")} title={t("aria_label.n_font")} className=" rounded-[3.2px] bg-[#EBEBEB] w-[23px] h-[22px] flex items-center justify-center text-[11.5px] text-secondary dark:!text-secondary font-bold focus:shadow-[0_0_6px_0px_#32435F] focus:dark:shadow-[0_0_6px_0px_#cdcdcd] hover:shadow-[0_0_6px_0px_#32435F] hover:dark:shadow-[0_0_6px_0px_#cdcdcd]" onClick={() => handleFont("A")}>A</button>
                            <button aria-label={t("aria_label.i_font")} title={t("aria_label.i_font")} className=" rounded-[3.2px] bg-[#EBEBEB] w-[23px] h-[22px] flex items-center justify-center text-[11.5px] text-secondary dark:!text-secondary font-bold focus:shadow-[0_0_6px_0px_#32435F] focus:dark:shadow-[0_0_6px_0px_#cdcdcd] hover:shadow-[0_0_6px_0px_#32435F] hover:dark:shadow-[0_0_6px_0px_#cdcdcd]" onClick={() => handleFont("+A")}>+A</button>
                            <div className="h-[22px] flex items-center pl-[5px] cursor-pointer">
                                <button tabIndex={theme === "light" ? 0 : -1} aria-label={theme === "light" ? t("aria_label.dark") : ""} title={theme === "light" ? t("aria_label.dark") : ""} className={`border border-secondary dark:border-white rounded-l-[3.2px] w-[23px] h-full flex items-center justify-center ${theme === "dark" ? "bg-white cursor-default" : "bg-transparent cursor-pointer focus:shadow-[0_0_6px_0px_#32435F] focus:dark:shadow-[0_0_6px_0px_#cdcdcd] hover:shadow-[0_0_6px_0px_#32435F] hover:dark:shadow-[0_0_6px_0px_#cdcdcd]"}`} onClick={() => handleThemeSwitch("dark")}><DarkMode className={` ${theme === "dark" ? "fill-black" : "fill-[#CCC525]"}`} /></button>
                                <button tabIndex={theme === "dark" ? 0 : -1} aria-label={theme === "dark" ? t("aria_label.light") : ""} title={theme === "dark" ? t("aria_label.light") : ""} className={`border border-secondary border-l-0 dark:border-white rounded-r-[3.2px] w-[23px] h-full flex items-center justify-center ${theme === "light" ? "bg-secondary cursor-default" : "bg-transparent cursor-pointer focus:shadow-[0_0_6px_0px_#32435F] focus:dark:shadow-[0_0_6px_0px_#cdcdcd] hover:shadow-[0_0_6px_0px_#32435F] hover:dark:shadow-[0_0_6px_0px_#cdcdcd]"}`} onClick={() => handleThemeSwitch("light")}><LightMode className={` ${theme === "light" ? "fill-white" : "fill-[#CCC525]"}`} /></button>
                            </div>
                        </div>

                        <div className='px-[10px] block mds:hidden relative' ref={ref1}>
                            <button aria-label={t("aria_label.utilities")} title={t("aria_label.utilities")} className=" rounded-[3.2px] bg-[#EBEBEB] w-[23px] h-[22px] flex items-center justify-center text-[11.5px] text-secondary dark:!text-secondary font-bold" onClick={() => setFontOpen(true)}>A

                                {fontOpen && <div className='shadow-lg bg-white dark:bg-[#272727] p-[4px] rounded-[3px] flex flex-col absolute top-[24px] z-[12]'>
                                    <button aria-label={t("aria_label.d_font")} title={t("aria_label.d_font")} className=" bg-[#EBEBEB] w-[23px] h-[22px] flex items-center justify-center text-[11.5px] text-secondary dark:!text-secondary font-bold" onClick={() => handleFont("A-")}>A-</button>
                                    <button aria-label={t("aria_label.n_font")} title={t("aria_label.n_font")} className=" bg-[#EBEBEB] w-[23px] h-[22px] flex items-center justify-center text-[11.5px] text-secondary dark:!text-secondary font-bold" onClick={() => handleFont("A")}>A</button>
                                    <button aria-label={t("aria_label.i_font")} title={t("aria_label.i_font")} className=" bg-[#EBEBEB] w-[23px] h-[22px] flex items-center justify-center text-[11.5px] text-secondary dark:!text-secondary font-bold" onClick={() => handleFont("+A")}>+A</button>
                                    <button tabIndex={theme === "light" ? 0 : -1} aria-label={theme === "light" ? t("aria_label.dark") : ""} title={theme === "light" ? t("aria_label.dark") : ""} className={`border border-secondary dark:border-white w-[23px] h-[22px] flex items-center justify-center ${theme === "dark" ? "bg-secondary cursor-default" : "bg-white cursor-pointer"}`} onClick={() => handleThemeSwitch("dark")}><DarkMode className={` ${theme === "dark" ? "fill-white" : "fill-[#CCC525]"}`} /></button>
                                    <button tabIndex={theme === "dark" ? 0 : -1} aria-label={theme === "dark" ? t("aria_label.light") : ""} title={theme === "dark" ? t("aria_label.light") : ""} className={`border border-secondary dark:border-white w-[23px] h-[22px] flex items-center justify-center ${theme === "light" ? "bg-secondary cursor-default" : "bg-white cursor-pointer"}`} onClick={() => handleThemeSwitch("light")}><LightMode className={` ${theme === "light" ? "fill-white" : "fill-[#CCC525]"}`} /></button>
                                </div>}
                            </button>

                        </div>
                        <div className="px-[10px] border-l border-opacity-[0.2] border-secondary dark:border-white relative" ref={ref}>
                            <button className='gap-[8px] flex items-center focus:underline hover:underline rounded-[5px] text-secondary dark:text-white' onClick={() => isLogin ? dispatch(dataActions.setProfile(true)) : dispatch(dataActions?.setLoginOpen(true))} onFocus={() => isLogin && dispatch(dataActions.setProfile(true))}>
                                <Login />
                                {!!user ? <p className='text-secondary dark:text-white text-[11px] mds:text-[12px] mm:text-[13px] font-bold truncate w-[65px]'>{user?.first_name} {" "} {user?.last_name}</p> : <p className='text-secondary dark:text-white text-[11px] mds:text-[12px] mm:text-[13px] font-bold'>{t("main_header.login")}</p>}
                            </button>
                            {profile && <ProfilePopup />}

                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default MainHeader;