/* eslint-disable react-hooks/exhaustive-deps */
import { BrowserRouter as Router } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
import Main from "./routes/main/Main";
import City from "./routes/city/City";
import Loader from "./components/layout/Loader";
import useAxios from "./shared/hooks/use-axios";
import { url } from "./shared/utils/url";
import { getApp } from "./shared/utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "./shared/store/data-Slice";
import { Helmet } from 'react-helmet';
import app from '../package.json';
import CacheBuster from 'react-cache-buster';

const MainRouter = () => {
    const theme: any = useSelector((state: any) => state.ui.theme);
    const currentCity: string = useSelector((state: any) => state.data.currentCity);
    let [loader, setLoader] = useState<any>(true);
    const [tenantData, setTanantData] = useState<any[]>([]);
    const Components: any = !loader && getApp(tenantData);
    const dispatch = useDispatch();

    useEffect(() => {
        if (theme === "dark") {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }

        !!theme && localStorage.setItem("theme", theme);
    }, [theme]);

    const getCityData = async () => {
        try {
            dispatch(dataActions.setIsLoading(true));
            const res: any = await useAxios.get(`/en${url?.city}`);

            if (res && res?.status === 200) {
                // for tenant setting
                let tenant: any = "";
                let arr: any[] = [];
                let cityObj: any = {};
                let helpLineObj: any = {};
                for (let i = 0; i < res?.data?.length; i++) {
                    const el = res?.data[i];
                    let domainCode: string = (url?.env === "live" ? el?.code : url?.env === "prod" ? `${el?.code}-upmrc-prod` : `${el?.code}-upmrc-uat`)
                    if (window.location.hostname?.includes(domainCode)) {
                        tenant = window.location.hostname?.replace(`${domainCode}.`, "")
                    }

                    let obj: any;
                    if (!!tenant) {
                        obj = {
                            subdomain: domainCode + "." + tenant,
                            app: City,
                            main: false
                        }
                    } else {
                        obj = {
                            subdomain: domainCode + "." + window.location.hostname,
                            app: City,
                            main: false
                        }
                    }

                    helpLineObj[el.name.toLowerCase()] = el?.emergency_nums

                    if (!cityObj?.hasOwnProperty(el?.code)) {
                        cityObj[el.name.toLowerCase()] = el?.id
                    }
                    arr.push(obj)
                }

                if (url?.env === "live") {
                    arr.push({
                        subdomain: !!tenant ? tenant : window.location.hostname,
                        app: Main,
                        main: true
                    })
                } else if (url?.env === "prod") {
                    arr.push({
                        subdomain: "upmrc-prod." + window.location.hostname?.toString()?.replace("upmrc-prod.", ""),
                        app: Main,
                        main: true
                    })
                } else {
                    arr.push({
                        subdomain: "upmrc-uat." + window.location.hostname?.toString()?.replace("upmrc-uat.", ""),
                        app: Main,
                        main: true
                    })
                }

                setTanantData(arr);
                setLoader(false);

                // for city name wise id get
                dispatch(dataActions.setCities(cityObj));
                dispatch(dataActions.setHelpLine(helpLineObj));
                dispatch(dataActions.setIsLoading(false));
            }
        } catch (error) {
            dispatch(dataActions.setIsLoading(false));
            console.error("error while feching city data", error);
        }
    };

    useEffect(() => {
        getCityData()
    }, [])

    return (
        <>
            <CacheBuster
                currentVersion={app.version}
                isEnabled={true}
                reloadOnDowngrade={true}
                isVerboseMode={true}
                loadingComponent={<Loader />}
                metaFileDirectory={'.'}
                onCacheClear={(refreshCacheAndReload: () => Promise<void>) => { }}
            >
                <>
                    {!!currentCity && <Helmet>
                        <title>Uttar Pradesh Metro Rail Corporation - {(currentCity?.[0]?.toUpperCase() + currentCity?.slice(1))} Metro (UPMRC)</title>
                        <meta name="description" content={`Official website of UPMRC for ${(currentCity?.[0]?.toUpperCase() + currentCity?.slice(1))} Metro`} />
                        <meta name="keywords" content={`${(currentCity?.[0]?.toUpperCase() + currentCity?.slice(1))} Metro`} />
                    </Helmet>}
                    <Suspense fallback={<Loader />}>
                        {!loader &&
                            <Router>
                                <Components />
                            </Router>
                        }
                    </Suspense>
                </>
            </CacheBuster>
        </>
    );
};

export default MainRouter;